import { Helmet } from 'react-helmet-async';

interface SEOHeadProps {
    title?: string;
    description?: string;
}

const SEOHead: React.FC<SEOHeadProps> = ({
    title,
    description = "Save money on your home improvements with Home Savings Direct"
}) => {
    const fullTitle = title ? `${title} | Home Savings Direct` : 'Home Savings Direct';

    return (
        <Helmet>
            <title>{fullTitle}</title>
            <meta name="description" content={description} />
            <link rel="icon" type="image/png" href="/favicon.png" />
            <link rel="icon" type="image/x-icon" href="/favicon.ico" />
        </Helmet>
    );
};

export default SEOHead; 