import { Metric } from 'web-vitals';

// Analytics Types
type EventCategory = 'Error' | 'User' | 'Performance' | 'Form' | 'Navigation';

interface AnalyticsEvent {
    category: EventCategory;
    action: string;
    label?: string;
    value?: number;
    nonInteraction?: boolean;
}

// Initialize dataLayer
window.dataLayer = window.dataLayer || [];

// Track events
export const trackEvent = (action: string, params: Record<string, any> = {}) => {
    if (window.gtag) {
        window.gtag('event', action, params);
    }
};

// Track errors
export const trackError = (error: Error | string, componentStack?: string) => {
    const errorMessage = typeof error === 'string' ? error : error.message;
    const errorName = typeof error === 'string' ? 'Error' : error.name;

    trackEvent('error', {
        error_name: errorName,
        error_message: errorMessage,
        error_stack: componentStack,
        non_interaction: true
    });
};

// Track page views
export const trackPageView = (path: string) => {
    if (window.gtag) {
        window.gtag('event', 'page_view', {
            page_path: path,
            page_title: document.title
        });
    }
};

// Performance monitoring configuration
const PERFORMANCE_SAMPLE_RATE = 0.1; // Only track 10% of performance events

// Determine if we should sample this session
const shouldSamplePerformance = Math.random() < PERFORMANCE_SAMPLE_RATE;

// Track critical performance metrics
const trackPerformanceMetric = (name: string, value: number) => {
    if (!shouldSamplePerformance) return;

    // Only track specific critical metrics
    const criticalMetrics = new Set([
        'FCP',
        'LCP',
        'CLS',
        'FID',
        'TTFB'
    ]);

    if (!criticalMetrics.has(name)) {
        return;
    }

    // Only log in development when explicitly enabled
    if (process.env.NODE_ENV === 'development' && process.env.VITE_DEBUG_PERFORMANCE) {
        console.log(`[Performance] ${name}: ${value.toFixed(2)}ms`);
    }

    trackEvent('performance', {
        metric_name: name,
        metric_value: value,
        non_interaction: true
    });
};

// Simplified web vitals reporting
export const reportWebVitals = (metric: { name: string; value: number }) => {
    if (!shouldSamplePerformance) return;

    trackPerformanceMetric(metric.name, metric.value);
};

// Track form interactions
export const trackFormInteraction = (formId: string, action: string, step?: number) => {
    trackEvent('form_interaction', {
        form_id: formId,
        action,
        step
    });
};
