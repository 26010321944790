import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import type { Metric } from 'web-vitals';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
import './index.css';

console.log('Starting application...');

const root = document.getElementById('root');
if (!root) {
  console.error('Root element not found');
  throw new Error('Root element not found');
}

try {
  console.log('Mounting application...');
  createRoot(root).render(
    <StrictMode>
      <ErrorBoundary>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </ErrorBoundary>
    </StrictMode>
  );
  console.log('Application mounted successfully');
} catch (error) {
  console.error('Error mounting application:', error);
}

// Load web-vitals only if not blocked
const loadWebVitals = async () => {
  try {
    const { onCLS, onFID, onFCP, onLCP, onTTFB } = await import('./utils/webVitals');
    // Initialize performance metrics
    onCLS((metric: Metric) => console.log('CLS:', metric.value));
    onFID((metric: Metric) => console.log('FID:', metric.value));
    onFCP((metric: Metric) => console.log('FCP:', metric.value));
    onLCP((metric: Metric) => console.log('LCP:', metric.value));
    onTTFB((metric: Metric) => console.log('TTFB:', metric.value));
  } catch (error) {
    console.warn('Web Vitals measurement disabled:', error);
  }
};

// Load web-vitals after main content
if (document.readyState === 'complete') {
  loadWebVitals();
} else {
  window.addEventListener('load', loadWebVitals);
}
