import React from 'react';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  return (
    <header className="bg-white">
      <div className="flex justify-between items-center max-w-7xl mx-auto px-4">
        {/* Logo */}
        <Link to="/" className="text-[#0052FF] font-bold">
          Home Savings Direct
        </Link>

        {/* Navigation Links */}
        <div className="flex items-center space-x-8">
          <Link to="/services" className="text-gray-600">Services</Link>
          <Link to="/how-it-works" className="text-gray-600">How It Works</Link>
          <Link to="/who-we-are" className="text-gray-600">Who We Are</Link>
        </div>

        {/* Phone Number */}
        <a href="tel:+18009390766" className="bg-[#0052FF] text-white px-4 py-2 rounded-lg">
          (800) 939-0766
        </a>
      </div>
    </header>
  );
};

export default Header;