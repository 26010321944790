import React from 'react';

interface ErrorFallbackProps {
    onReset: () => void;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ onReset }) => {
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
            <div className="text-center p-8 max-w-lg">
                <h1 className="text-2xl font-bold text-gray-900 mb-4">
                    Something went wrong
                </h1>
                <p className="text-gray-600 mb-6">
                    We apologize for the inconvenience. Our team has been notified of this issue.
                </p>
                <div className="space-x-4">
                    <button
                        onClick={onReset}
                        className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
                    >
                        Try Again
                    </button>
                    <button
                        onClick={() => window.location.reload()}
                        className="bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300 transition-colors"
                    >
                        Reload Page
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ErrorFallback; 