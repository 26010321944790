import React, { Component, ErrorInfo, ReactNode } from 'react';
import ErrorFallback from './ErrorFallback';
import { trackError } from '../utils/analytics';

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
    error?: Error;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false
    };

    public static getDerivedStateFromError(error: Error): State {
        return { hasError: true, error };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Track error with analytics
        trackError(error);

        // Log to console in development
        if (process.env.NODE_ENV === 'development') {
            console.error('Error caught by boundary:', error);
            console.error('Component stack:', errorInfo.componentStack);
        }
    }

    public render() {
        if (this.state.hasError) {
            return <ErrorFallback onReset={() => this.setState({ hasError: false })} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
