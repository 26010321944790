import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import LoadingSpinner from '../components/common/LoadingSpinner';

const LeadGenPage = React.lazy(() => import('../pages/LeadGenPage'));
const ServicesPage = React.lazy(() => import('../pages/ServicesPage'));
const ServiceLandingPage = React.lazy(() => import('../pages/ServiceLandingPage'));
const HowItWorksPage = React.lazy(() => import('../pages/HowItWorksPage'));
const WhoWeArePage = React.lazy(() => import('../pages/WhoWeArePage'));
const PrivacyPage = React.lazy(() => import('../pages/PrivacyPage'));
const TermsPage = React.lazy(() => import('../pages/TermsPage'));

const AppRoutes: React.FC = () => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <Routes>
                <Route path="/" element={<LeadGenPage />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/services/:slug" element={<ServiceLandingPage />} />
                <Route path="/how-it-works" element={<HowItWorksPage />} />
                <Route path="/who-we-are" element={<WhoWeArePage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="/terms" element={<TermsPage />} />
            </Routes>
        </Suspense>
    );
};

export default AppRoutes;
